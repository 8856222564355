<template>
  <div class="addText-wrap">
    <NavBar/>
    <div class="content">
      <div class="line1">
        <h2>分类名称</h2>
        <div>{{$route.query.category_name}}</div>
      </div>
      <div class="line2">
        <h2>短信内容</h2>
        <van-field
          v-model="template"
          rows="3"
          autosize
          type="textarea"
          maxlength="280"
          placeholder="请输入短信类容"
          show-word-limit
        />
        <p>
          1、单条短信长度（即签名、短信内容和退订方式数字之和）不得超过280字。<br>
          2、超过70字，按60字/条，逐条计费。单个汉字、标点、英文都
          算一个字、回车记两个字。
        </p>
      </div>
      <button class="Lbutton" type="submit" value="取消" @click="$router.go(-1)">取消</button>
      <button class="Notice" type="submit" value="确定" @click="templateAdd">确定</button>
    </div>
  </div>

</template>

<script>
import NavBar from '@/components/NavBar'
import table from '@/api/table'
export default {
  name: 'addText',
  components:{NavBar},
  mixins: [],
  data() {
    return {
      template:'',
    }
  },
  mounted() {
  },
  methods: {
    templateAdd(){
      table.templateAdd({category_id:parseInt(this.$route.query.category_id),template:this.template}).then(response=>{
        if(response.data.result){
          this.$router.go(-1);//返回上一层
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .addText-wrap {
    .content{
      min-height: calc(100vh - 112px);
      padding: 0 30px;
      margin-top: 20px;
      background: #fff;
      .van-field{
        line-height: 70px;
        padding: 0 23px;
        background: #FFFFFF;
        border: 2px solid #E9E9E9;
        border-radius: 10px;
      }
      h2{
        font-size: 26px;
        font-weight: bold;
        color: #333333;
      }
      p{
        margin-top: 27px;
        font-weight: 500;
        font-size: 24px;
        color: #999999;
      }
      .line1{
        padding-top: 45px;
        div{
          border: 2px solid #E9E9E9;
          border-radius: 10px;
          background: #EEE;
          margin-top: 27px;
          line-height: 70px;
          padding-left:25px;
          font-size: 26px;
          font-weight: bold;
          color: #666666;
        }
      }
      .line2{
        margin-top:42px;
        .van-field {
          padding-top: 20px;
          font-size: 26px;
          line-height: 32px;
        }
        h2{margin-bottom: 27px}
        /deep/.van-field__word-limit{color: #2C6BF8;}
      }
    }
    .Lbutton{
      border: 2px solid #2C6BF8;
      width: 300px;
      height: 80px;
      border-radius: 40px;
      margin-top: 80px;
      color: #2C6BF8;
      margin-left: 50px;
      font-size: 30px;
      background: #F4F8FF;
    }
    .Notice{
      border: 1px solid #2C6BF8;
      width: 300px;
      height: 80px;
      border-radius: 40px;
      margin-top: 80px;
      color: #ffffff;
      font-size: 30px;
      margin-left: 40px;
      background-color: #2C6BF8;
    }
  }
</style>
